.ListBare {
	list-style: none;
	padding: 0;
	
	> li,
	.ListBare-item {
		padding: .279em 0;
	}
}

.ListGroup {
	list-style: none;
	padding: 0;
}

.ListGroup-item {
	background-color: @grey-lighter;
	color: #000;
	display: block;
	margin-bottom: 1px;
	padding: .335em .694em;
	position: relative;
	text-align: center;
	
	a {
		color: inherit;
	}
}

.ListBare--bordered {
	> li {
		border-bottom: 1px dotted @grey-lighter;
	}
}

.ListPaging {
	margin: 0;
	padding: 0;
}

.ListPaging-child {
	counter-increment: step-counter;
	color: @grey;
	display: inline-block;
	font-weight: 700;
	letter-spacing: 2px;
	margin-right: -0.279em;
	text-transform: uppercase;
	width: 50%;
	
	&:nth-child(n+3) {
		padding-top: 1rem;
	}
	
	@media @mq-sm {
		width: 25%;
		
		&:nth-child(n+3) {
			padding-top: 0;
		}
	}
}

.ListPaging-action {
	color: currentColor;
}

.ListPaging-child::before {
	border: 2px solid;
	border-radius: 100%;
	content: counter(step-counter);
	display: block;
	height: 36px;
	letter-spacing: 0;
	line-height: 32px;
	margin-right: 8px;
	text-align: center;
	vertical-align: middle;
	width: 36px;
	
	@media @mq-md {
		display: inline-block;
	}
}

.ListPaging-child.is-active {
	color: #000;
}


/**
 * The list-inline object simply displays a list of items in one line.
 */
.ListInline {
	margin: 0 0 1.2rem;
	padding: 0;
	list-style: none;

	> .ListInline-item {
		display: inline-block;
		line-height: 1.2;
		margin-right: 8px;
	}
}

/**
 * Pipe delimited list to semantically mark up lists of tags, etc.
 *
 * N.B. This component requires that you remove the whitespace between LIs.
 *      The simplest (and valid) way to achieve this is by omitting the
 *      closing `</li>` tag.
 */
 
.ListInline--delimited {
	> .ListInline-item + .ListInline-item {
	  border-left: 1px solid;
	  padding-left: 8px;
	}
}


.ListInline--breakEven {
	.u-cf;
	
	> .ListInline-item {
		display: block;
		float: left;
		
		&:nth-child(2n+3) {
		  clear: left;
		}
	}
	
	@media @mq-sm {
		> .ListInline-item {
			display: inline-block;
			float: none;
		}
	}
}