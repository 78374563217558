.u-quickpen {
	font-family: @font-cursive;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	text-transform: none;
}

.u-semibold {
	font-weight: 600;
}