/* Play icon that appears on top of images
 * 
 * Instructions: wrap an image element inside a div or anchor element
 * that has a class of .Play
 *
 * <a class="Play [Play--lg Play--inverse]" href="">
 *   <img src="" width="" alt="">
 * </a>
 */

.Play {
	color: #fff;
  display: block;
  position: relative;
  
  &:before,
  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
  }
  
  &:before {
    background: rgba(0,0,0,.65);
    border-radius: 100%;
    height: 48px;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 48px;
  }
  
  &:after {
    border: 10px solid transparent;
    border-left-color: currentColor;
    border-left-width: 16px;
    border-right-width: 0;
    height: 0;
    left: 50%;
    transform: translate(-40%, -50%);
    top: 50%;
    width: 0;
  }
}

.Play--inverse {
	color: #000;
	
	&::before {
		background: rgba(255,255,255,.65);
	}
	
	&::after {
		border-left-color: currentColor;
	}
}

.Play--bordered {
	border: 3px solid currentColor;
	border-bottom-width: 9px;
	color: @grey-darker;
	
	&::before {
		border: 3px solid currentColor;
	}
}

.Play--lg {
	@media @mq-sm {
		&::before {
			height: 72px;
			width: 72px;
		}
		
		&::after {
			border-width: 20px;
			border-left-width: 32px;
			border-right-width: 0;
		}
	}
}