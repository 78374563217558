.u-hidden { display: none; }
.u-block { display: block; }
.u-flex { display: flex; }
.u-grid { display: grid; }

@media @mq-sm {
	.sm\:u-flex { display: flex; }
	.sm\:u-grid { display: grid; }
}

@media @mq-md {
	.md\:u-hidden { display: none; }
	.md\:u-block { display: block; }
	.md\:u-flex { display: flex; }

	.u-md-hide {
		display: none;
	}

	.u-md-tableCell {
		display: table-cell !important;
	}
}

@media @mq-lg {
	.lg\:u-flex { display: flex; }
	.u-lg-hide {
		display: none;
	}
}

@media @mq-xl {
	.xl\:u-flex { display: flex; }
}
