/* CSS Document */

.Tabs {
  margin-bottom: 0 !important;
  margin-left: 0;
	padding: 0;
	.u-cf;
}
.Tabs .Tabs-title {
	float: left;
	list-style: none;
	margin-bottom: 0 !important;
	position: relative;
}

.Tabs .Tabs-title > a {
	display: block;
	color: @grey-dark;
	font-size: 1rem;
	padding: 1rem 1.44rem;
}

.Tabs .Tabs-title > a:hover {
	background-color: @grey-light;
}

.Tabs .Tabs-title.is-active a {
	background-color: @grey-darker;
	color: #fff;
	font-weight: 700;
}

.Tabs.is-vertical .Tabs-title {
	position: inherit;
	float: none;
	display: block;
	top: auto;
}

.Tabs-content {
  margin-bottom: 1.5rem;
  width: 100%;
	.u-cf;
}

.Tabs-content > .content {
	display: none !important;
	float: left;
	padding: 0.9375em 0;
	width: 100%;
}

.Tabs-content > .content.is-active {
  display: block !important;
  float: none;
}
    .Tabs-content > .content.contained {
      padding: 0.9375em; }
  .Tabs-content--vertical {
    display: block; }
    .Tabs-content--vertical > .content {
      padding: 0 0.9375em; }

@media only screen and (min-width: 40.0625em) {
  .Tabs.vertical {
    float: left;
    margin: 0;
    margin-bottom: 1.25rem !important;
    max-width: 20%;
    width: 20%; }

  .Tabs-content.vertical {
    float: left;
    margin-left: -1px;
    max-width: 80%;
    padding-left: 1rem;
    width: 80%; } }
.no-js .Tabs-content > .content {
  display: block !important;
  float: none; }
