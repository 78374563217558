.Form-group {
	margin: 0 0 1rem 0;
}

.Help {
	color: darken(@grey, 10%);
	display: inline-block;
	font-size: .833em;
	font-weight: 600;
	line-height: 1.2;
	margin: 0;
	padding: 2px 6px;

	&.is-erred {
		background: lighten(red, 40%);
		color: rgba(0,0,0,.65);
	}
}


.PaymentForm-number {
	position: relative;

	input {
		padding-right: 55px;
	}
}

.PaymentForm-card {
	background: url('../img/cc-front.png') no-repeat;
	background-size: contain;
	display: block;
	height: 20px;
	margin-top: -10px;
	position: absolute;
	right: 10px;
	top: 50%;
	width: 40px;

	.visa + & {
		background-image: url('../img/cc-visa.png');
	}

	.amex + & {
		background-image: url('../img/cc-amex.png');
	}

	.mastercard + & {
		background-image: url('../img/cc-mc.png');
	}

	.discover + & {
		background-image: url('../img/cc-disc.png');
	}
}

.PaymentForm-cvc {
	position: relative;

	.PaymentForm-card {
		background: url('../img/cc-back.png') no-repeat;
		background-size: contain;
	}
}

.PaymentForm-exp {

}




.c-form__group {
  display: flex;
  flex-direction: column;
  margin-bottom: .833em;
  position: relative;

	ul.ff-errors {
		background: @red-lightest;
		color: @red;
		font-size: .875rem;
		list-style: none;
		margin: 0;
		padding: .5rem;
	}
}

.c-form__check {
  display: flex;
  flex-direction: row;

  .c-form__label {
    padding-left: 4px;
  }
}

.c-form__control {
	border: 1px solid @grey-lighter;
  display: block;
  font-weight: 700;
	padding: .5rem;
  width: 100%;

  &:focus {
    border-bottom-color: inherit;
    outline: none;

    & + .c-form__label {
      color: #000;
    }
  }
}

.c-form__check-input {
  margin-right: 8px;
  margin-top: 3px;
}

.c-form__help {
  background: @grey-lighter;
  color: @grey-dark;
  font-size: .694em;
  text-transform: uppercase;
}

.c-form--float-label {
  .c-form__group {
		border: 2px solid @grey-lighter;

    &:focus-within {
      .c-form__label {
        color: #000;
				opacity: 1;
				top: .5rem;
      }

			.c-form__control {
				padding-top: 1.25rem;

				&::placeholder {
				  opacity: 0;
				}
			}
    }
  }

  .c-form__control {
    border: none;
    border-radius: 0;
    height: 3.5em;
    transition: padding-top 125ms ease-in;
  }

  .c-form__label {
    color: @grey-dark;
    font-size: .75rem;
    left: .5rem;
    line-height: 1;
		opacity: 0;
    position: absolute;
		top: 5px;
		transition: top 700ms ease, opacity 700ms ease;

    svg {
      display: block;
    }
  }

  &.c-form--islands {
    .c-form__control {
      background: #fff;
      border: none;
      border-radius: .25rem;
      box-shadow: 0 2px 4px rgba(0,0,0,.1);
      padding-left: 1rem;
      padding-right: 1rem;

      &:not(:placeholder-shown) {
        + .c-form__label {
          top: .375rem;
        }
      }
    }

    .c-form__label {
      left: 1rem;
    }
  }
}

textarea {
  transition: height .3s ease;
}

textarea.c-form__control:not(:placeholder-shown) {
  height: 5em;
}

.c-form--search {
  .c-form__group {
    margin: 0;
  }

  .c-form__label {
    height: 1em;
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1em;
  }

  .c-form__control {
    text-indent: calc(10px + 1em)
  }
}

.c-form__label--no-float {
  opacity: 0;
}
