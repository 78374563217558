.GlobalNav {
  color: @grey-light;
  font-size: .833em;
	margin: 0 auto;
	position: relative;
  
  a {
    color: #fff;
    text-decoration: none;
  }
	
	> .Container {
		background: @grey-dark;
    background: fade(@grey-dark, 96%);
    border-left: 1px solid #000;
    border-right: 1px solid #000;
  }
	
	&.is-sticky {
		background: @grey-dark;
    background: fade(@grey-dark, 96%);
		position: fixed !important;
		top: 2.986em !important;
		width: 100% !important;
		
		> .Container {
			background: none;
		}
	}
  
  /* active carousel dot */
  .slick-active {
    button {
      background: @grey-lighter;
    }
  }
	
	&.is-hovered,
	&:hover {
		max-height: 2000px !important;
		position: absolute;
		width: 100%;
	}
}

.GlobalNav-section {
  display: block;
	max-height: 0;
	overflow: hidden;
  padding: 0 1rem;
  table-layout: fixed;
  width: 100%;
	
	&.is-hovered,
	&:hover,
	.is-expanded & {
		border-bottom: 1px solid @grey;
		display: table;
		max-height: 20em;
		padding-bottom: 1rem;
		padding-top: 1rem;
	}
}

.GlobalNav-list,
.GlobalNav-media {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.GlobalNav-list,
.GlobalNav-children {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.GlobalNav-list {
  border-right: 1px dotted @grey-light;
	margin-right: -0.279em;
  padding-right: 1em;
  text-align: center;
  text-transform: uppercase;
}

.GlobalNav-title {
	color: #fff;
	font-size: 1.2em;
  font-weight: 700;
  margin: 0 0 1em 0;
}

.GlobalNav-media {
  padding-left: 1em;
	
	
}
.GlobalNav-mediaHeader {
	color: @grey-light;
	font-size: 1.2em;
	font-weight: 700;
	margin: 0 0 0 2.074rem;
}

.GlobalNav-child {
  line-height: 2.048;
}

.GlobalNav-list--horizontal {
	border-right-color: transparent;
	text-align: left;
	width: 100%;
	
	.GlobalNav-child {
		display: inline-block;
		margin-right: 1rem;
	}
}

@media (min-width: 32em) {
	.GlobalNav-list--horizontal {
		.GlobalNav-title,
		.GlobalNav-children {
			display: inline-block;
		}
		
		.GlobalNav-title {
			border-right: 1px dotted @grey-light;
			padding-right: 1rem;
			text-align: center;
		}
		
		.GlobalNav-children {
			padding-left: 1rem;
		}
	}
	
  .GlobalNav-list,
	.GlobalNav-list--horizontal .GlobalNav-title {
    width: 25%;
  }
	
  
  .GlobalNav-media {
    width: 75%;
  }
	
	.GlobalNav-list--horizontal {
		width: 100%;
	}
}