/**
 * High-, page-level styling.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 * 3. Ensure the page always fills at least the entire height of the viewport.
 * 4. Prevent certain mobile browsers from automatically zooming fonts.
 * 5. Fonts on OSX will look more consistent with other systems that do not
 *    render text using sub-pixel anti-aliasing.
 */
html {
  font-family: @font-family-base;
  font-size: 1em; /* [1] */
	font-weight: 300;
  line-height: 1.44; /* [1] */
  background-color: @background-base;
  color: @color-base;
  min-height: 100%; /* [3] */
  -webkit-text-size-adjust: 100%; /* [4] */
  -ms-text-size-adjust: 100%; /* [4] */
  -moz-osx-font-smoothing: grayscale; /* [5] */
  -webkit-font-smoothing: antialiased; /* [5] */
}

body.is-expanded {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;

  .SiteHeader > .Container:first-of-type {
    display: none;
  }
}
