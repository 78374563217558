@media @mq-sm {
  .c-in-this-issue {
    columns: 2;

    p {
      margin: 0 0 1rem;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
}

@media @mq-md {
  .c-in-this-issue {
    column-gap: 2rem;
  }
}
