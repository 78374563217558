.Promo {
	background-color: @grey-lighter;
	display: block;
	font-size: .833em;
	max-width: @site-max-width;
	padding: 1.5em 1em;
	width: 100%;
	z-index: 40; /* behind NavBar and Siteheader GlobalNav */

	.Layout + & {
		border-top: 1px solid #000;
	}
}

.Promo-header {
	margin-bottom: -.579em;
}

.Promo-title {
	font-family: @font-cursive;
	line-height: .833;
	margin: 0;
}

.Promo-body {
	position: relative;
}

@media @mq-lg {
	.Promo {
		margin-left: 0;
		position: relative;
		width: auto;
	}
}
