.Search {
  display: flex;
}

/**
 * There are two possible types of child. `sizeFill` will expand to fill all
 * of the remaining space not filled by `sizeFit` elements.
 *
 * 1. Zero out any default spacing that might be on an element (e.g., `li`);
 *    Margin has no effect when coupled with `display: table-cell`.
 * 2. All cells are top-aligned by default
 */

/**
 * Make sure the main content block expands to fill the remaining space.
 */

.Search-sizeFill {
  align-items: center;
  display: flex;
  flex: 1;
}

.Search-sizeFit {
  img {
    max-width: none;
  }
}

.Search-button {
  background: url('/img/icon-search.svg') no-repeat center center;
  background-size: 16px 16px;
  overflow: hidden;
  text-indent: 100%;
}
