.Block {
  display: block;
	
	> a {
		display: block;
	}
}

.Block-figure {
  margin-bottom: .579em;
}

.Block-title {
	font-weight: 300;
	line-height: 1.2;
  margin: 0 0 13px;
	
	&:only-of-type,
	&:last-of-type {
		margin-bottom: 0;
	}
	
	a {
		color: inherit;
	}
}

h6.Block-title {
	font-weight: 600;
}

h1.Block-title,
h2.Block-title {
	text-transform: uppercase;
}

h4.Block-title {
	font-size: 1.2em;
}

.Block-meta {
	color: @grey-light;
	font-family: @font-alt;
	font-size: .833em;
	font-style: italic;
	font-weight: 700;
	text-transform: uppercase;
	
	a {
		color: inherit;
	}
}

.Block-body,
.Block-subhead {
	margin: .335em 0;
	padding: 0;
}

.Block-subhead {
	margin: 0;
	
	@media @mq-sm {
		font-size: 1.2rem;
	}
}

.Block-body {
	border-bottom: 1px solid @grey-light;
	padding-bottom: .694em;
}



.Block--center {
  text-align: center;
}