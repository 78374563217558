.Carousel {
  position: relative;
  display: block;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
}
.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.Carousel-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  max-width: @site-max-width;
  opacity: .25;
  width: 100vw;

  &.slick-active {
    opacity: 1;
  }
}

[dir='rtl'] .Carousel-slide {
  float: right;
}

.Carousel-slide.slick-loading img {
  display: none;
}
.Carousel-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .Carousel-slide {
  display: block;
}

.slick-loading .Carousel-slide {
  visibility: hidden;
}

.slick-vertical .Carousel-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

/* Theming the carousel is below, using Slick Carousel's naming conventions
   ======================================================================== */
/* Navigation dots */
.slick-dots {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;

  li {
    display: inline-block;
    padding: 8px 6%;


  }
  button {
    background: @grey-light;
    border-radius: 100%;
    height: 11px;
    text-indent: 11px;
    width: 11px;
  }
}

.slick-active {
  button {
    background: @grey-dark;
  }
}

.Carousel--hideDots {
	.slick-dots {
		display: none !important; /* !important overrides slick.js inline element styling */
	}
}


/* Navigation arrows */
.slick-prev,
.slick-next {
	background-size: 16px auto;
	background-position: center;
  height: 48px;
  position: absolute;
  margin-top: -24px;
	overflow: hidden;
  text-indent: 72px;
  top: 50%;
  width: 24px;

  &:focus,
  &:active {
    outline: 1px dotted @grey-light;
  }
}

.slick-prev {
  background-image: url('../img/icon-arrow-plain-left.svg');
  background-repeat: no-repeat;
  left: 8px;

  .Carousel--fancy & {
    background-image: url('../img/icon-arrow-left.svg');
  }
}

.slick-next {
  background-image: url('../img/icon-arrow-plain-right.svg');
  background-repeat: no-repeat;
  right: 8px;

  .Carousel--fancy & {
    background-image: url('../img/icon-arrow-right.svg');
  }
}

.Carousel--arrowsOut {
	margin: 0 20px;

	.slick-next {
		right: -20px;
	}

	.slick-prev {
		left: -20px;
	}
}

.Carousel--arrowsLg,
.Carousel--arrowsMd,
.Carousel--arrowsSm {
	@media @mq-sm {
		.slick-next,
		.slick-prev {
			background-size: 24px auto;
		}

		&.Carousel--arrowsOut {
			margin: 0 28px;

			.slick-next {
				right: -28px;
			}

			.slick-prev {
				left: -28px;
			}
		}
	}
}

.Carousel--arrowsLg,
.Carousel--arrowsMd {
	@media @mq-md {
		.slick-next,
		.slick-prev {
			background-size: contain;
			width: 48px;
		}

		&.Carousel--arrowsOut {
			margin: 0 48px;

			.slick-next {
				right: -48px;
			}

			.slick-prev {
				left: -48px;
			}
		}
	}
}

.Carousel--arrowsLg {
  @media @mq-lg {
		.slick-next,
		.slick-prev {
			width: 72px;

			&.Carousel--arrowsOut {
				margin: 0 72px;

				.slick-next,
				.slick-prev {
					height: 72px;
					margin-top: -36px;
					width: 72px;
				}

				.slick-next {
					right: -72px;
				}

				.slick-prev {
					left: -72px;
				}
			}
		}
	}


}
