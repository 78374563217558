.u-container { width: 100%; }

@media @mq-sm {
  .u-container { max-width: 640px; }
}

@media @mq-md {
  .u-container { max-width: 768px; }
}

@media @mq-lg {
  .u-container { max-width: 1024px; }
}

@media @mq-xl {
  .u-container { max-width: 1280px; }
}
