.Container,
.o-container {
  padding: 1em;
  margin: 0 auto;
  max-width: @site-max-width;
  width: 100%;
}

@media @mq-lg {
  .Container,
  .o-container {
		width: 90%;
	}
}
