/**
 * The button classes are best applied to links, buttons, and submit inputs.
 * These components can be used in forms, as calls to action, or as part of the
 * general UI of the site/app.
 */

/**
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Inherit text color from ancestor.
 * 4. Normalize `line-height`. For `input`, it can't be changed from `normal` in Firefox 4+.
 * 5. Prevent button text from being selectable.
 * 6. Make sure `input` will wrap text across multiple lines.
 */

.Button {
  -webkit-appearance: none; /* 1 */
  background: @grey-lighter;
  border-color: transparent;
	border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box; /* 2 */
  color: inherit; /* 3 */
  cursor: pointer;
  display: inline-block;
  font-family: @font-alt;
	font-weight: 700;
  line-height: 1; /* 4 */
  margin: 0;
  padding: .833em;
  position: relative;
  text-align: center;
  text-decoration: none;
	text-transform: uppercase;
  user-select: none; /* 5 */
  white-space: normal; /* 6 */

	.u-quickpen {
		font-weight: 700;
	}
}

/**
 * Remove excess padding and border in Firefox 4+
 */

.Button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

.Button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

/**
 * UI states
 */

.Button:hover,
.Button:focus,
.Button:active {
  text-decoration: none;
}

.Button:disabled,
.Button.is-disabled {
  cursor: default;
  opacity: .6;
}

.Button--fill {
	display: block;
}

.Button--sm {
	font-size: .833em;
}

.Button--lg {
	font-size: 1.44em;

	.u-quickpen {
		font-size: 1.44em;
		font-weight: 400;
		line-height: .482;
	}
}

.Button--bare {
	background-color: transparent;
  border-color: transparent;
	padding-left: 0;
	padding-right: 0;
}

.Button--black {
	background: #000;
	color: #fff;
}

.Button--gold {
	background: linear-gradient(to right, #ebc17d 0%,#dfb77c 19%,#c08329 60%,#eedcc3 100%);
	background-image: url("../img/lb-GoldFoil.jpg");
	background-position: right bottom;
	border: none;
	color: #fff;
	text-shadow: 0 1px 1px rgba(0,0,0,.5);
}

.Button--rounded {
	border-radius: 4px;
}

.Button--tall {
	padding: .579rem;
}

.Button--simple {
	background: darken(@grey-light, 5%);
	color: #fff;
	font-family: @font-family-base;
}

.Button--condensed {
	padding: 2px 4px;
}
