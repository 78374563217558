.QtyControl {
	display: table;
	table-layout: fixed;
	vertical-align: top;
	width: 100%;
	
	.QtyControl-input {
		border: none;
		box-shadow: none;
		line-height: 1;
		margin: 0;
		min-width: 2rem;
		text-align: center;
		width: 100%;
	}
	
	/* coupled with .Button styles */
	.QtyControl-stepper {
		width: 100%;
	}
}

.QtyControl-item {
	display: table-cell;
	vertical-align: top;
}

