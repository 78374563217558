.Entry {
}

.Entry-header {
	margin: 0 auto;
	max-width: 45rem;
	padding: 1.44em 0 0;
}

.Entry-headline {
	font-weight: 600;
	line-height: 1;
	margin: 0;
}

.Entry-subhead {
	font-weight: 300;
	line-height: 1.2;
	margin: 0;
}

.Entry-meta {
	font-size: .833em;
	text-transform: uppercase;
}

.Entry-byline {
	font-size: .694em;
	font-style: italic;
	margin: 0 auto;
	max-width: 45rem;
	padding: .335rem 0;
}
	
.Entry-body {
	.u-cf;
	
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 600;
		margin-bottom: -.402em;
		text-transform: uppercase;
	}
	
	a {
		font-weight: 600;
	}
	
	h1,h2,h3,h4,h5,h6,
	iframe,
	p,
	ul, ol,
	.EntryObject--center,
	.EntryFigure--center {
		display: block;
		margin-left: auto !important;
		margin-right: auto !important;
		max-width: 45rem;
	}
	
	> .EntryObject,
	> .EntryFigure {
		clear: both;
	}
	
	+ .ShreBare {
		padding-top: 2.986rem;
	}
}

.Entry-section {
	border-top: 1px solid @grey;
	padding-top: 1em;
	padding-bottom: 1em;
	
	.ShareBar + & {
		border-top: none;
	}
}

.Entry--recipe {
	.Entry-body {
		> p,
		> ul,
		> ol {
			font-size: 1.2rem;
			font-weight: 300;
			margin-top: 0;
		}
	}
}

/* Entry Object and Figure
   ===================================================================== */

.EntryFigure {
	> a {
		display: block;
	}
}

.EntryFigure-caption,
.EntryObject-caption {
	clear: both;
	font-family: @font-alt;
	font-size: .833em;
	font-style: italic;
	font-weight: 500;
	padding-top: .402em
}

.EntryFigure-credit {
	font-family: @font-sans-serif;
}

/* Left or right alignments */
.EntryFigure--left,
.EntryObject--left,
.EntryFigure--right,
.EntryObject--right {
	@media @mq-sm {
		max-width: 40%;
		
		.EntryFigure {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.EntryFigure--left,
.EntryObject--left {
	@media @mq-sm {
		float: left;
		margin-right: 2em;
	}
}

.EntryFigure--right,
.EntryObject--right {
	@media @mq-sm {
		float: right;
		margin-left: 2em;
	}
}

.EntryFigure--center,
.EntryObject--center,
.EntryFigure--fill,
.EntryObject--fill {
	text-align: center;
}

.EntryObject {
	.u-cf;
	
	.EntryFigure {
		padding: .335em 0;
	}
}

/* EntryObject grid system
 *
 * Must contain .EntryFigure children
 */
.EntryObject--2up,
.EntryObject--3up,
.EntryObject--4up {
	margin-left: -.335em; /* match left/right padding of .EntryFigure below */
	margin-right: -.335em; /* match left/right padding of .EntryFigure below */
	
	.EntryFigure {
		float: left;
		padding-left: .335em;
		padding-right: .335em;
		width: 50%;
	}
	
	.EntryObject-caption {
		padding-left: .335em;
	}
}

@media @mq-sm {
	.EntryObject--3up,
	.EntryObject--4up {
		.EntryFigure {
			width: 33.33333%;
		}
	}
}

@media @mq-md {
	.EntryObject--4up {
		.EntryFigure {
			width: 25%;
		}
	}
}

/* Entry Pull Quote
   ====================================================================== */
.EntryQuote {
	border-left: 6px solid @grey-light;
	padding-left: 1em;
}

.EntryQuote-text {
	font-family: @font-alt;
	font-size: 1.2em;
	font-style: italic;
	padding: .482em 0;
	margin: 0;
	
	@media @mq-sm {
		font-size: 1.44em;
	}
	
	/* if layout is delayed, allow larger font between breakpoints */
	.Layout--delayed & {
		@media @mq-md and @mq-lg-max {
			font-size: 2.074em;
		}
	}
}

.EntryQuote-author {
	font-size: .694em;
	font-weight: 600;
	text-transform: uppercase;
	
	&::before {
		content: '\2014\0020'; /* em dash \2014 and space \0020 */
	}
}

/* Entry Gallery
   ====================================================================== */
.EntryGallery {
	border-bottom: .833rem solid #000;
	border-top: .833rem solid #000;
	margin-left: 0;
	margin-right: 0;
	padding-bottom: .579em;
	position: relative;
}

.EntryGallery-action {
	background: #fff;
	bottom: -.833rem;
	font-family: @font-alt;
	font-size: .833rem;
	font-style: italic;
	line-height: 1rem;
	padding: 0 1em;
	position: absolute;
	right: 0;
	text-transform: uppercase;
}