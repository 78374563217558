.u-sticky { position: sticky; }
.u-absolute { position: absolute; }
.u-relative { position: relative; }
.u-fixed { position: fixed; }

.u-posAbsolute {
	position: absolute;
}

.u-inset-0 { top: 0; right: 0; bottom: 0; left: 0; }

.u-top-0 { top: 0; }
.u-top-12 { top: 3rem; }
.u-top-16 { top: 4rem; }
.u-right-0 { right: 0; }
.u-bottom-0 { bottom: 0; }
.u-left-0 { left: 0; }

.u-bottom-0 { bottom: 0; }

.u-left-0 { left: 0; }

.u-z-0 { z-index: 0; }
.u-z-10 { z-index: 10; }

@media @mq-md {
	.u-md-posAbsolute {
		position: absolute;
	}
}
