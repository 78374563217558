table {
  background: #FFFFFF;
  border: none;
  margin-bottom: 1.2rem;
	max-width: 100%;
  table-layout: auto;
	width: 100%;
}

table caption {
  background: transparent;
  color: @grey-darker;
  font-size: 1rem;
  font-weight: bold;
}

table thead {
  background: darken(@grey-lighter, 3%);
}

table thead tr th,
table thead tr td {
  color: @grey-darker;
  font-size: 0.833rem;
  font-weight: bold;
  padding: 0.579rem 0.694rem 0.694rem;
}

table tfoot {
  background: darken(@grey-lighter, 3%);
}

table tfoot tr th,
table tfoot tr td {
  color: @grey-darker;
  font-size: 0.833rem;
  font-weight: bold;
  padding: 0.579rem 0.694rem 0.694rem;
}

table tr th,
table tr td {
  color: #222222;
  font-size: 0.875rem;
  padding: 0.579rem 0.694rem;
  text-align: left;
}

table tr.even, table tr.alt, table tr:nth-of-type(even) {
  background: lighten(@grey-lighter, 8%);
}

table thead tr th,
table tfoot tr th,
table tfoot tr td,
table tbody tr th,
table tbody tr td,
table tr td {
  display: table-cell;
  line-height: 1.2rem;
}