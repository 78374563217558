/* Background color
   ========================================================================== */
.u-bg-body { background-color: @background-base; }

.u-bg-transparent { background-color: transparent; }

/* black */
.u-bg-black { background-color: #000; }

/* white */
.u-bg-white { background-color: #fff; }

/* grey */
.u-bg-gray-100,
.u-bg-grey-100,
.u-bg-grey-lightest { background-color: @gray-100; }
.u-bg-gray-200 { background-color: @gray-200; }
.u-bg-gray-300,
.u-bg-grey-300,
.u-bg-grey-lighter { background-color: @gray-300; }
.u-bg-grey-400,
.u-bg-gray-400 { background-color: @gray-400; }
.u-bg-gray-800 { background-color: @gray-800; }
.u-bg-gray-900 { background-color: @gray-900; }


/* white */
.u-bg-white { background-color: #fff; }

/* pink */
.u-bg-pink,
.u-bg-pink-200 { background-color: @pink-200; }

/* teal */
.u-bg-teal-500 { background-color: @teal-500; }
.u-bg-teal-700 { background-color: @teal-700; }
