.u-border-0 { border-width: 0; }
.u-border { border-width: 1px; }
.u-border-8 { border-width: 8px; }
.u-border-t { border-top-width: 1px; }
.u-border-t-2 { border-top-width: 2px; }
.u-border-t-4 { border-top-width: 4px; }
.u-border-r { border-right-width: 1px; }
.u-border-b { border-bottom-width: 1px; }

.u-border-grey-100,
.u-border-gray-100 { border-color: @gray-100; }
.u-border-gray-200 { border-color: @gray-200; }
.u-border-grey-300,
.u-border-gray-300 { border-color: @gray-300; }
.u-border-gray-400 { border-color: @gray-400; }
.u-border-grey-800,
.u-border-gray-800 { border-color: @gray-800; }

.u-border-pink-200 { border-color: @pink-200; }

.u-rounded { border-radius: .25rem; }
