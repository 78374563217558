.Flag {
	display: table;
	width: 100%;
}

.Flag--layoutFixed {
	table-layout: fixed;
}

/* Flag-figure and Flag-body should be paired with sizing utilities to
 * apply widths to them
 */

.Flag-figure,
.Flag-body {
	display: table-cell;
	vertical-align: middle;
}

.Flag-figure {
	border-right: 1px solid;
	padding-right: 1em;
	
	img {
		max-width: none;
	}
}

.Flag-body {
	padding-left: 1em;
	width: 100%;
}