.Box {
	display: block;
	font-size: 1em;
	margin: 0;
	padding: 1em;
	.u-cf;

	> :last-child {
		margin-bottom: 0;
	}
}

.Box-header {
	border-bottom: 1px solid currentColor;
	padding-bottom: .335em;
}

.Box-header--alt {
	border-bottom-width: 2px;

	&:after {
		border-top: 1px solid @grey-light;
		content: '';
		display: block;
		position: relative;
		top: .194em;
	}
}

.Box-title {
	font-family: @font-alt;
	font-style: italic;
	font-weight: 700;
	margin: 0 0 .335rem;
	padding: 0;
	text-transform: uppercase;
}

.Box-subhead {
	color: @grey-dark;
	font-size: 1rem;
	font-style: italic;
	font-weight: 400;
	margin: 0;
}

.Box-body {
	padding-top: 4%;

	> :first-child {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}
}

.Box--light {
	color: @grey-light;
}

.Box--gold {
	background: @gold;
}

.Box--flushH {
	padding-left: 0;
	padding-right: 0;
}

.Box--flushV {
	padding-bottom: 0;
	padding-top: 0;
}

.Box--flush {
	padding: 0;
}

.Box--verticalSeparation {
	margin-bottom: 1em;
	margin-top: 1em;
}

.Box--xs {
	font-size: .694em;
}

.Box--sm {
	font-size: .833em;
}

.Box--lg {
	font-size: 1.44em;
}

.Box--xl {
	font-size: 2.048em;
}

.Box--borderedL {
	.Box-header,
	.Box-body {
		border-left: 1px solid currentcolor;
		padding-left: .335em;
	}
}

.Box--alt {
	background: lighten(@grey-lighter, 5%);
	border-radius: 4px;

	.Box-header {
		border: none;
		color: #000;
		background: url('/img/dot.svg') repeat-x left bottom;
	}

	.Box-title {
		font-style: normal;
	}

	.Box-action {
		background: #000;
		color: #fff;
		display: inline-block;
		font-family: @font-alt;
		left: 50%;
		position: relative;
		text-transform: uppercase;
		transform: translateX(-50%);
	}
}
