/**
 * Must be accompanied by .Grid object
 *
 * <div class="Cart">
 *   <div class="Cart-body">
 *     <div class="Cart-row Grid">
 *       <div class="Cart-cell Grid-cell u-size1of2"></div>
 *       <div class="Cart-cell Grid-cell u-size1of2"></div>
 *     </div><!--/end cart row -->
 *   </div><!--/cart body -->
 * </div><!--/cart -->
 */

.Cart {
	display: block;
	width: 100%;
}

.Cart-body {
	border-bottom: 1px solid @grey-lighter;
	border-top: 1px solid @grey-lighter;
	margin-bottom: 1rem
}

.Cart-row {
	.Cart-body & {
		&:nth-child(odd) {
		  background-color: lighten(@grey-lighter, 6%);
	  }
	}
}

.Cart-cell {
	padding: .579rem;
	vertical-align: top;
}

.LineItem-product {
	width: 100%;
}

.LineItem-qty {
	min-width: 175px;
	vertical-align: middle;
	width: 100%;
}

.LineItem-price {
	border-top: 1px dotted @grey-light;
	font-weight: 600;
	width: 100%;
}

@media @mq-sm {
	.LineItem-product {
		width: 50%;
	}
	.LineItem-qty {
		width: 50%;
	}
}

@media @mq-md {
	.LineItem-qty {
		width: 25%;
	}

	.LineItem-price {
		border-top: none;
		width: 25%;
	}
}
