input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea {
  -webkit-appearance: none;
  font-family: inherit;
  color: @grey-dark;
  font-size: 1em;
  margin: 0;
  padding: .579em;
  width: 100%;
  box-sizing: border-box;
  transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
}

textarea {
  height: auto;
  min-height: 50px;
}

select {
  width: 100%;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button {
  background: none;
  border: none;
  line-height: 1;
  padding: 0;
}

/* Label Styles */
label {
  font-size: 0.875rem;
  cursor: pointer;
  display: block;
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 0;

	> input {
		margin-bottom: 0 !important;
	}
}

input[type="text"]:disabled, input[type="password"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="month"]:disabled, input[type="week"]:disabled, input[type="email"]:disabled, input[type="number"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="color"]:disabled, textarea:disabled {
  background-color: @grey-light;
  cursor: default;
}

input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0; }

/* Respect enforced amount of rows for textarea */
textarea[rows] {
  height: auto; }

/* Not allow resize out of parent */
textarea {
  max-width: 100%; }

select {
  -webkit-appearance: none !important;
  border-radius: 0;
  background-color: #FAFAFA;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+);
  background-position: 100% center;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 2px;
  border-color: @grey-lighter;
  padding: .579rem 1rem .579rem .579rem;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.75);
  line-height: normal;
  border-radius: 0;
}
select::-ms-expand {
  display: none;
}
  select:hover {
    background-color: #f3f3f3;
    border-color: #999999; }
  select:disabled {
    background-color: #DDDDDD;
    cursor: default; }
  select[multiple] {
    height: auto; }

/* Normalize file input width */
input[type="file"] {
  width: 100%;
 }

/* "Honeypot" field in contact form to catch spam */
input[name="hotdog"] {
	display: none;
}

/* hide input spinner on number inputs */
input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
