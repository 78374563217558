.u-flex-row { flex-direction: row; }
.u-flex-row-reverse { flex-direction: row-reverse; }
.u-flex-col { flex-direction: column; }
.u-flex-col-reverse { flex-direction: column-reverse; }

.u-flex-wrap { flex-wrap: wrap; }

.u-items-start { align-items: flex-start; }
.u-items-center { align-items: center; }

.u-justify-center { justify-content: center; }
.u-justify-between { justify-content: space-between; }
.u-justify-end { justify-content: flex-end; }

.u-flex-1 { flex: 1; }

.u-flex-break { flex-basis: 100%; height: 0; }

@media @mq-sm {
  .sm\:u-flex-row { flex-direction: row; }
  .sm\:u-flex-1 { flex: 1; }
}

@media @mq-md {
  .md\:u-flex-1 { flex: 1; }
  .md\:u-justify-center { justify-content: center; }
}
