.Layout {
	display: flex;
	flex-direction: column;
	padding: 1em 0;
}

.Layout--withGutter {
	margin: 0 -1em;

	.Layout-main,
	.Layout-secondary {
		padding-left: 1em;
		padding-right: 1em;
	}
}

.Layout-main {
	min-width: 0;
}

.Layout-secondary {
	margin: 0 auto;
	max-width: 500px;
	padding-top: 1.2rem;
}

@media @mq-md {
  .Layout {
		flex-direction: row;
    margin: 0 -1em;
  }

  .Layout-main {
		border-right: 1px solid #000;
    flex: 1;
  }

  .Layout-secondary {
		padding-top: 0;
		width: ~"calc(300px + 3.583em)";
  }

	.Layout--delayed {
		.Layout-main,
		.Layout-secondary {
			border-right: none;
			display: block;
		}

		.Layout-main {
			width: 100%;
		}
	}
}

@media @mq-lg {
	.Layout--withGutter {
		.Layout-main,
		.Layout-secondary {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}

	.Layout--delayed {
		.Layout-main {
			border-right: 1px solid #000;
			width: ~"calc(100% - (300px + 3em))";
		}

		.Layout-secondary {
			padding-top: 0;
			width: ~"calc(300px + 3em)";
		}
	}
}
