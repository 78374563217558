/*
 *  <section class="Page">
 *    <header class="Page-header">
 *      <h2 class="Page-subtitle">Video</h2>
 *      <h1 class="Page-title">Eats</h1>
 *    </header>
 *    [body]
 *  </section>
 */
 
 .Page {
	 
 }
 
.Page-header {
	margin-bottom: 1.44em;
}

.Page-subtitle,
.Page-title {
	display: inline-block;
	font-size: 2.488rem;
	font-weight: 700;
	line-height: 1;
	margin: 0 .194em 0 0;
	padding-right: .233em;
	text-transform: uppercase;
	vertical-align: middle;
}

.Page-title > .Page-subtitle {
	border-right: none;
	display: block;
}

.Page-subtitle {
	border-right: 1px solid currentcolor;
	color: @grey-light;
	font-weight: 300;
}

.Page-title + .Page-subtitle {
	border-left: 1px solid currentcolor;
	padding-left: .402em;
}

.Page-title ~ .Page-subtitle {
	border-right: none;
}

.Page-subhead {
	font-family: @font-alt;
	margin-top: 0;
}