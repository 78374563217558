/**
 * Sizing utilities
 */

 /* Width
    ========================================================================== */
.u-w-3 { width: .75rem; }
.u-w-4 { width: 1rem; }
.u-w-5 { width: 1.25rem; }
.u-w-6 { width: 1.5rem; }
.u-w-8 { width: 2rem; }
.u-w-10 { width: 2.5rem; }
.u-w-12 { width: 3rem; }
.u-w-16 { width: 4rem; }
.u-w-20 { width: 5rem; }
.u-w-24 { width: 6rem; }
.u-w-32 { width: 8rem; }
.u-w-40 { width: 10rem; }
.u-w-full { width: 100%; }

 @media @mq-sm {
   .sm\:u-w-12 { width: 3rem; }
   .sm\:u-w-32 { width: 8rem; }
 }

 @media @mq-md {
   .md\:u-w-64 { width: 16rem; }
 }

/* Intrinsic widths
   ========================================================================== */

/**
 * Make an element shrink wrap its content.
 */

.u-sizeFit,
.u-sizeFitAlt {
  display: block !important;
  float: left !important;
  width: auto !important;
}

.u-sizeFitAlt {
  float: right !important;
}

/**
 * Make an element fill the remaining space.
 * N.B. This will hide overflow.
 */

.u-sizeFill {
  display: block !important;
  overflow: hidden !important;
  width: auto !important;
}

/**
 * An alternative method to make an element fill the remaining space.
 * N.B. Do not use if child elements might be wider than the remaining space.
 * In Chrome, Safari, and Firefox it results in undesired layout.
 */

.u-sizeFillAlt {
  display: table-cell !important;
  max-width: 100% !important;
  width: 10000px !important;
}

/**
 * Make an element the width of its parent.
 */

.u-sizeFull {
  box-sizing: border-box !important;
  display: block !important;
  width: 100% !important;
}

/* Proportional widths
   ========================================================================== */

/**
 * Specify the proportional width of an object.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
 */

.u-size1of12 {
  width: ~"calc(100% * 1 / 12)" !important;
}

.u-size1of10 {
  width: 10% !important;
}

.u-size1of8 {
  width: 12.5% !important;
}

.u-size1of6,
.u-size2of12 {
  width: ~"calc(100% * 1 / 6)" !important;
}

.u-size1of5,
.u-size2of10 {
  width: 20% !important;
}

.u-size1of4,
.u-size2of8,
.u-size3of12 {
  width: 25% !important;
}

.u-size3of10 {
  width: 30% !important;
}

.u-size1of3,
.u-size2of6,
.u-size4of12 {
  width: ~"calc(100% * 1 / 3)" !important;
}

.u-size3of8 {
  width: 37.5% !important;
}

.u-size2of5,
.u-size4of10 {
  width: 40% !important;
}

.u-size5of12 {
  width: ~"calc(100% * 5 / 12)" !important;
}

.u-size1of2,
.u-size2of4,
.u-size3of6,
.u-size4of8,
.u-size5of10,
.u-size6of12 {
  width: 50% !important;
}

.u-size7of12 {
  width: ~"calc(100% * 7 / 12)" !important;
}

.u-size3of5,
.u-size6of10 {
  width: 60% !important;
}

.u-size5of8 {
  width: 62.5% !important;
}

.u-size2of3,
.u-size4of6,
.u-size8of12 {
  width: ~"calc(100% * 2 / 3)" !important;
}

.u-size7of10 {
  width: 70% !important;
}

.u-size3of4,
.u-size6of8,
.u-size9of12 {
  width: 75% !important;
}

.u-size4of5,
.u-size8of10 {
  width: 80% !important;
}

.u-size5of6,
.u-size10of12 {
  width: ~"calc(100% * 5 / 6)" !important;
}

.u-size7of8 {
  width: 87.5% !important;
}

.u-size9of10 {
  width: 90% !important;
}

.u-size11of12 {
  width: ~"calc(100% * 11 / 12)" !important;
}

.u-size1of1 {
	width: 100%;
}

@media @mq-sm {
  .sm\:u-w-48 { width: 12rem; }
  /**
   * Make an element shrink wrap its content.
   */

  .u-sm-sizeFit,
  .u-sm-sizeFitAlt {
    display: block !important;
    float: left !important;
    width: auto !important;
  }

  .u-sm-sizeFitAlt {
    float: right !important;
  }

  /**
   * Make an element fill the remaining space.
   * N.B. This will hide overflow.
   */

  .u-sm-sizeFill {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }

  /**
   * An alternative method to make an element fill the remaining space.
   * N.B. Do not use if child elements might be wider than the remaining space.
   * In Chrome, Safari, and Firefox it results in undesired layout.
   */

  .u-sm-sizeFillAlt {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }

  /**
   * Make an element the width of its parent.
   */

  .u-sm-sizeFull {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }

  /* Proportional widths: breakpoint 1 (small)
     ========================================================================== */

  /**
   * Specify the proportional width of an object.
   * Intentional redundancy build into each set of unit classes.
   * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
   */

  .u-sm-size1of12 {
    width: ~"calc(100% * 1 / 12)" !important;
  }

  .u-sm-size1of10 {
    width: 10% !important;
  }

  .u-sm-size1of8 {
    width: 12.5% !important;
  }

  .u-sm-size1of6,
  .u-sm-size2of12 {
    width: ~"calc(100% * 1 / 6)" !important;
  }

  .u-sm-size1of5,
  .u-sm-size2of10 {
    width: 20% !important;
  }

  .u-sm-size1of4,
  .u-sm-size2of8,
  .u-sm-size3of12 {
    width: 25% !important;
  }

  .u-sm-size3of10 {
    width: 30% !important;
  }

  .u-sm-size1of3,
  .u-sm-size2of6,
  .u-sm-size4of12 {
    width: ~"calc(100% * 1 / 3)" !important;
  }

  .u-sm-size3of8 {
    width: 37.5% !important;
  }

  .u-sm-size2of5,
  .u-sm-size4of10 {
    flex-basis: auto;
    width: 40% !important;
  }

  .u-sm-size5of12 {
    width: ~"calc(100% * 5 / 12)" !important;
  }

  .u-sm-size1of2,
  .u-sm-size2of4,
  .u-sm-size3of6,
  .u-sm-size4of8,
  .u-sm-size5of10,
  .u-sm-size6of12 {
    width: 50% !important;
  }

  .u-sm-size7of12 {
    width: ~"calc(100% * 7 / 12)" !important;
  }

  .u-sm-size3of5,
  .u-sm-size6of10 {
    width: 60% !important;
  }

  .u-sm-size5of8 {
    width: 62.5% !important;
  }

  .u-sm-size2of3,
  .u-sm-size4of6,
  .u-sm-size8of12 {
    width: ~"calc(100% * 2 / 3)" !important;
  }

  .u-sm-size7of10 {
    width: 70% !important;
  }

  .u-sm-size3of4,
  .u-sm-size6of8,
  .u-sm-size9of12 {
    width: 75% !important;
  }

  .u-sm-size4of5,
  .u-sm-size8of10 {
    width: 80% !important;
  }

  .u-sm-size5of6,
  .u-sm-size10of12 {
    width: ~"calc(100% * 5 / 6)" !important;
  }

  .u-sm-size7of8 {
    width: 87.5% !important;
  }

  .u-sm-size9of10 {
    width: 90% !important;
  }

  .u-sm-size11of12 {
    width: ~"calc(100% * 11 / 12)" !important;
  }

  .u-sm-size1of1 {
  	width: 100%;
  }

	.u-sm-sizeAuto {
		width: auto !important;
	}
}

@media @mq-md {
  /**
   * Make an element shrink wrap its content.
   */

  .u-md-sizeFit,
  .u-md-sizeFitAlt {
    display: block !important;
    float: left !important;
    width: auto !important;
  }

  .u-md-sizeFitAlt {
    float: right !important;
  }

  /**
   * Make an element fill the remaining space.
   * N.B. This will hide overflow.
   */

  .u-md-sizeFill {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }

  /**
   * An alternative method to make an element fill the remaining space.
   * N.B. Do not use if child elements might be wider than the remaining space.
   * In Chrome, Safari, and Firefox it results in undesired layout.
   */

  .u-md-sizeFillAlt {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }

  /**
   * Make an element the width of its parent.
   */

  .u-md-sizeFull {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }

  /* Proportional widths: breakpoint 2 (medium)
     ========================================================================== */

  /**
   * Specify the proportional width of an object.
   * Intentional redundancy build into each set of unit classes.
   * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
   */

  .u-md-size1of12 {
    width: ~"calc(100% * 1 / 12)" !important;
  }

  .u-md-size1of10 {
    width: 10% !important;
  }

  .u-md-size1of8 {
    width: 12.5% !important;
  }

  .u-md-size1of6,
  .u-md-size2of12 {
    width: ~"calc(100% * 1 / 6)" !important;
  }

  .u-md-size1of5,
  .u-md-size2of10 {
    width: 20% !important;
  }

  .u-md-size1of4,
  .u-md-size2of8,
  .u-md-size3of12 {
    width: 25% !important;
  }

  .u-md-size3of10 {
    width: 30% !important;
  }

  .u-md-size1of3,
  .u-md-size2of6,
  .u-md-size4of12 {
    width: ~"calc(100% * 1 / 3)" !important;
  }

  .u-md-size3of8 {
    width: 37.5% !important;
  }

  .u-md-size2of5,
  .u-md-size4of10 {
    width: 40% !important;
  }

  .u-md-size5of12 {
    width: ~"calc(100% * 5 / 12)" !important;
  }

  .u-md-size1of2,
  .u-md-size2of4,
  .u-md-size3of6,
  .u-md-size4of8,
  .u-md-size5of10,
  .u-md-size6of12 {
    width: 50% !important;
  }

  .u-md-size7of12 {
    width: ~"calc(100% * 7 / 12)" !important;
  }

  .u-md-size3of5,
  .u-md-size6of10 {
    width: 60% !important;
  }

  .u-md-size5of8 {
    width: 62.5% !important;
  }

  .u-md-size2of3,
  .u-md-size4of6,
  .u-md-size8of12 {
    width: ~"calc(100% * 2 / 3)" !important;
  }

  .u-md-size7of10 {
    width: 70% !important;
  }

  .u-md-size3of4,
  .u-md-size6of8,
  .u-md-size9of12 {
    width: 75% !important;
  }

  .u-md-size4of5,
  .u-md-size8of10 {
    width: 80% !important;
  }

  .u-md-size5of6,
  .u-md-size10of12 {
    width: ~"calc(100% * 5 / 6)" !important;
  }

  .u-md-size7of8 {
    width: 87.5% !important;
  }

  .u-md-size9of10 {
    width: 90% !important;
  }

  .u-md-size11of12 {
    width: ~"calc(100% * 11 / 12)" !important;
  }

	.u-md-size1of1,
	.u-md-size2of2,
	.u-md-size3of3,
	.u-md-size4of4,
	.u-md-size5of5,
	.u-md-size6of6,
	.u-md-size8of8,
	.u-md-size10of10,
	.u-md-size12of12 {
		width: 100% !important;
	}
}

@media @mq-lg {
  /**
   * Make an element shrink wrap its content.
   */

  .u-lg-sizeFit,
  .u-lg-sizeFitAlt {
    display: block !important;
    float: left !important;
    width: auto !important;
  }

  .u-lg-sizeFitAlt {
    float: right !important;
  }

  /**
   * Make an element fill the remaining space.
   * N.B. This will hide overflow.
   */

  .u-lg-sizeFill {
    display: block !important;
    overflow: hidden !important;
    width: auto !important;
  }

  /**
   * An alternative method to make an element fill the remaining space.
   * N.B. Do not use if child elements might be wider than the remaining space.
   * In Chrome, Safari, and Firefox it results in undesired layout.
   */

  .u-lg-sizeFillAlt {
    display: table-cell !important;
    max-width: 100% !important;
    width: 10000px !important;
  }

  /**
   * Make an element the width of its parent.
   */

  .u-lg-sizeFull {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }

  /* Proportional widths: breakpoint 3 (large)
     ========================================================================== */

  /**
   * Specify the proportional width of an object.
   * Intentional redundancy build into each set of unit classes.
   * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
   */

  .u-lg-size1of12 {
    width: ~"calc(100% * 1 / 12)" !important;
  }

  .u-lg-size1of10 {
    width: 10% !important;
  }

  .u-lg-size1of8 {
    width: 12.5% !important;
  }

  .u-lg-size1of6,
  .u-lg-size2of12 {
    width: ~"calc(100% * 1 / 6)" !important;
  }

  .u-lg-size1of5,
  .u-lg-size2of10 {
    width: 20% !important;
  }

  .u-lg-size1of4,
  .u-lg-size2of8,
  .u-lg-size3of12 {
    width: 25% !important;
  }

  .u-lg-size3of10 {
    width: 30% !important;
  }

  .u-lg-size1of3,
  .u-lg-size2of6,
  .u-lg-size4of12 {
    width: ~"calc(100% * 1 / 3)" !important;
  }

  .u-lg-size3of8 {
    width: 37.5% !important;
  }

  .u-lg-size2of5,
  .u-lg-size4of10 {
    width: 40% !important;
  }

  .u-lg-size5of12 {
    width: ~"calc(100% * 5 / 12)" !important;
  }

  .u-lg-size1of2,
  .u-lg-size2of4,
  .u-lg-size3of6,
  .u-lg-size4of8,
  .u-lg-size5of10,
  .u-lg-size6of12 {
    width: 50% !important;
  }

  .u-lg-size7of12 {
    width: ~"calc(100% * 7 / 12)" !important;
  }

  .u-lg-size3of5,
  .u-lg-size6of10 {
    width: 60% !important;
  }

  .u-lg-size5of8 {
    width: 62.5% !important;
  }

  .u-lg-size2of3,
  .u-lg-size4of6,
  .u-lg-size8of12 {
    width: ~"calc(100% * 2 / 3)" !important;
  }

  .u-lg-size7of10 {
    width: 70% !important;
  }

  .u-lg-size3of4,
  .u-lg-size6of8,
  .u-lg-size9of12 {
    width: 75% !important;
  }

  .u-lg-size4of5,
  .u-lg-size8of10 {
    width: 80% !important;
  }

  .u-lg-size5of6,
  .u-lg-size10of12 {
    width: ~"calc(100% * 5 / 6)" !important;
  }

  .u-lg-size7of8 {
    width: 87.5% !important;
  }

  .u-lg-size9of10 {
    width: 90% !important;
  }

  .u-lg-size11of12 {
    width: ~"calc(100% * 11 / 12)" !important;
  }
}

.u-w-1\/2 { width: 50%; }

@media @mq-sm {
  .sm\:u-w-1\/2 { width: 50%; }
  .sm\:u-w-1\/3 { width: 33.33333%; }
  .sm\:u-w-1\/4 { width: 25%; }

}

@media @mq-md {
  .md\:u-w-1\/2 { width: 50%; }
  .md\:u-w-1\/3 { width: 33.33333%; }
  .md\:u-w-1\/4 { width: 25%; }
  .md\:u-w-1\/6 { width: 16.66667%; }
}

/* Max Width
   ========================================================================== */
.u-max-w-xs { max-width: 20rem; }
.u-max-w-sm { max-width: 24rem; }
.u-max-w-md { max-width: 28rem; }
.u-max-w-lg { max-width: 32rem; }
.u-max-w-xl { max-width: 36rem; }
.u-max-w-2xl { max-width: 42rem; }
.u-max-w-3xl { max-width: 48rem; }
.u-max-w-4xl { max-width: 56rem; }
.u-max-w-full { max-width: 100%; }

/* Height
   ========================================================================== */
.u-h-3 { height: .75rem; }
.u-h-4 { height: 1rem; }
.u-h-6 { height: 1.5rem; }
.u-h-8 { height: 2rem; }
.u-h-12 { height: 3rem; }
.u-h-full { height: 100%; }
.u-h-screen { height: 100vh; }

/* Min Height
   ========================================================================== */
.u-min-h-screen { min-height: 100vh; }

@media @mq-sm {
  .sm\:u-h-12 { height: 3rem; }
}

@media @mq-md {
  .md\:u-max-w-2xl { max-width: 42rem; }
}
