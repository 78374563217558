:root {
  --grid-gutter-size: 1rem;
}

/**
 * Core grid component
 *
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */

 /**
  * All content must be contained within child elements.
  *
  * 1. Account for browser defaults of elements that might be the root node of
  *    the component.
  */

 .o-grid {
   box-sizing: border-box;
   display: flex; /* 1 */
   flex-flow: row wrap;
   margin: 0; /* 1 */
   padding: 0; /* 1 */
 }

 /**
  * No explicit width by default. Rely on combining a cell with a dimension
  * utility or a component class that extends 'Grid'.
  *
  * 1. Set flex items to full width by default
  * 2. Fix issue where elements with overflow extend past the
  *    `.o-grid > *` container - https://git.io/vw5oF
  */

 .o-grid > * {
   box-sizing: inherit;
   flex-basis: 100%; /* 1 */
   min-width: 0; /* 2 */
 }

 /**
  * Modifier: center align all grid cells
  */

 .o-grid--align-center {
   justify-content: center;
 }

 /**
  * Modifier: right align all grid cells
  */

 .o-grid--align-right {
   justify-content: flex-end;
 }

 /**
  * Modifier: middle-align grid cells
  */

 .o-grid--align-middle {
   align-items: center;
 }

 /**
  * Modifier: bottom-align grid cells
  */

 .o-grid--align-bottom {
   align-items: flex-end;
 }

 /**
  * Modifier: allow cells to equal distribute width
  *
  * 1. Provide all values to avoid IE10 bug with shorthand flex
  *    http://git.io/vllC7
  *
  *    Use `0%` to avoid bug in IE10/11 with unitless flex basis
  *    http://git.io/vllWx
  */

 .o-grid--fill > * {
   flex: 1 1 0%; /* 1 */
 }

 /**
  * Modifier: fit cells to their content
  */

 .o-grid--fit > * {
   flex-basis: auto;
 }

 /**
  * Modifier: all cells match height of tallest cell in a row
  */

 .o-grid--equal-height > * {
   display: flex;
 }

 /**
  * Modifier: gutters
  */

 .o-grid--with-gutter {
   margin: 0 calc(-0.5 * var(--grid-gutter-size));
 }

 .o-grid--with-gutter > * {
   padding: 0 calc(0.5 * var(--grid-gutter-size));
 }










 /* =========================================================================
    LEGACY CODE; DO NOT USE
    ========================================================================= */

/* Grid container
   ========================================================================== */

/**
 * All content must be contained within child `Grid-cell` elements.
 *
 * 1. Account for browser defaults of elements that might be the root node of
 *    the component.
 * 2. Remove inter-cell whitespace that appears between `inline-block` child
 *    elements.
 * 3. Ensure consistent default alignment.
 */

.Grid {
  display: block; /* 1 */
  font-size: 0; /* 2 */
  margin: 0; /* 1 */
  padding: 0; /* 1 */
  text-align: left; /* 3 */
}

/**
 * Modifier: center align all grid cells
 */

.Grid--alignCenter {
  text-align: center;
}

/**
 * Modifier: right align all grid cells
 */

.Grid--alignRight {
  text-align: right;
}

/**
 * Modifier: middle-align grid cells
 */

.Grid--alignMiddle > .Grid-cell {
  vertical-align: middle;
}

/**
 * Modifier: bottom-align grid cells
 */

.Grid--alignBottom > .Grid-cell {
  vertical-align: bottom;
}

/**
 * Modifier: gutters
 *
 * NOTE: this can trigger a horizontal scrollbar if the component is as wide as
 * the viewport. Use padding on a container, or `overflow-x:hidden` to protect
 * against it.
 */

.Grid--withGutter {
  margin: 0 -1rem;
}

.Grid--withGutter > .Grid-cell {
  padding: 0 1rem;
}

/* Grid cell
   ========================================================================== */

/**
 * No explicit width by default. Rely on combining `Grid-cell` with a dimension
 * utility or a component class that extends 'grid'.
 *
 * 1. Fundamentals of the non-float grid layout.
 * 2. Reset font size change made in `Grid`.
 * 3. Keeps content correctly aligned with the grid direction.
 * 4. Controls vertical positioning of units.
 * 5. Make cells full-width by default.
 */

.Grid-cell {
  display: inline-block; /* 1 */
  font-size: 1rem; /* 2 */
  margin: 0 -1px 0 0; /* added -1px right for some wierd android browser bug */
  padding: 0;
  text-align: left; /* 3 */
  vertical-align: top; /* 4 */
  width: 100%; /* 5 */
}

/**
 * Modifier: horizontally center one unit
 * Set a specific unit to be horizontally centered. Doesn't affect
 * any other units. Can still contain a child `Grid` object.
 */

.Grid-cell--center {
  display: block;
  margin: 0 auto;
}
