.Slideshow {
	background: #fff;
	background: rgba(255,255,255,.9);
	border: 1px solid @grey-lighter;
	bottom: 0;
	color: @color-base;
	display: none;
	left: 0;
	margin: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 9999;
}

.Slideshow:target,
.Slide:target {
	display: block;
}

.Slideshow-inner {
	background: #fff;
}

.Slideshow-figure {
	height: 62vh;
	text-align: center;
	
	img {
		max-height: 100%;
		width: auto;
	}
}

.Slideshow-body {
	height: ~"calc(38vh - 1em)"; /* remove 1em for .Slideshow padding */
	position: relative;
}

.SlideshowNav {
	border-bottom: 1px solid @grey-lighter;
	border-top: 1px solid @grey-lighter;
	font-size: 1rem;
	line-height: 1;
	.u-cf;
}

.SlideshowNav-item {
	float: left;
	padding: 1rem;
	width: 33.33333%;
}

.SlideshowNav-item--right {
	text-align: right;
}

.Slideshow-count {
	text-align: center;
}

.Slideshow-content {
	bottom: 0;
	left: 0;
	overflow-y: auto;
	padding: 1rem;
	position: absolute;
	top: 2.488em;
	top: 2.986rem;
	top: ~"calc(2.986rem + 2px)";
	width: 100%;
	
	a {
		font-weight: 400;
		text-decoration: underline;
	}
}

.Slideshow-title {
	color: @grey;
	font-family: @font-alt;
	font-size: 1.44rem;
	font-style: italic;
	font-weight: 400;
	margin-top: 0;
	text-transform: uppercase;
}

.Slideshow-close {
	color: #000;
	font-weight: 700;
	height: 33px;
	line-height: 33px;
	overflow: hidden;
	position: absolute;
	right: 1em;
	text-align: center;
	text-indent: 33px;
	text-shadow: 0 1px 0 #fff;
	top: 11px;
	width: 33px;
	z-index: 1;
	
	&:before {
		content: "\00d7";
		display: block;
		font-size: 48px;
		height: 33px;
		left: 0;
		position: absolute;
		text-indent: 0;
		top: 0;
		width: 33px;
	}
}

.Slideshow {
	.ShareBar {
		background: rgba(0,0,0,.5);
		border-bottom: none;
		bottom: 0;
		left: 0;
		line-height: 1;
		margin: 0;
		padding: 10px 5px;
		position: absolute;
		
		a {
			color: #fff;
		}
	}
}
		

@media @mq-sm and (orientation: landscape), @mq-md {
	.Slideshow-inner {
		border: 1px solid @grey-lighter;
		margin: 44px auto 0;
		max-height: 90vh;
		max-width: 900px;
		position: relative;
	}
	
	.Slideshow-figure,
	.Slideshow-body {
		height: auto;
		max-height: 90vh;
		width: 50%;
	}
	
	.Slideshow-figure {
		margin-right: -.279em;
		max-width: 800px;
		overflow: hidden;
		position: relative;
		
		img {
			max-height: 90vh;
		}
	}
	
	.Slideshow-body {
		bottom: 1em;
		height: auto;
		position: absolute;
		right: 0;
		top: 0;
	}
	
	.SlideshowNav {
		border-top: none;
	}
	
	.Slideshow-close {
		right: 10px;
		top: 10px;
	}
}

@media @mq-lg {
	.Slideshow-figure {
		width: 65%;
	}
	
	.Slideshow-body {
		width: 35%;
		max-width: 375px;
	}
}