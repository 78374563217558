/**
 * Requires: obj.flex-embed.less
 *
 *  <article class="Billboard FlexEmbed" style="background-image: url('images/LB29_Table_Spread_07.jpg');">
 *    <div class="FlexEmbed-ratio FlexEmbed-ratio--4by3 md-FlexEmbed-ratio--3by4"></div>
 *    
 *    <div class="FlexEmbed-content">
 *      <div class="Billboard-content u-[sm-|md-|lg-]posAbsolute">
 *        winter florals
 *      </div><!--/billboard content -->
 *    </div><!--/flex embed content -->
 *  </article><!--/billboard object -->
 */

.Billboard {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #000;
  margin-left: auto;
	margin-right: auto;
	position: relative;
	
	&.EntryGallery {
		border-top: none;
	}
	
	img {
		vertical-align: bottom;
	}
	
	> a {
		display: block;
	}
}

.Billboard-content {
	display: block;
	padding-bottom: .694rem;
	padding-top: .694rem;
	
	&:hover,
	&:active {
		text-decoration: none;
	}
}

.Billboard--overlay {
	.Billboard-content {
		background: rgba(255,255,255,.85);
		bottom: 0;
		left: 0;
		padding: .694rem 1rem;
		position: absolute;
		width: 100%;
	}
}

@media @mq-md {
	
	.Billboard-content--topLeft,
	.Billboard-content--topRight,
	.Billboard-content--bottomRight,
	.Billboard-content--bottomLeft {
		padding: 1.2rem;
		
		/* Billboard-content with full width should be flush */
		&[class*='size1of1'] {
			left: 0;
			right: 0;
		}
	}
	
	.md-Billboard--overlay {
		.Billboard-content {
			background: rgba(255,255,255,.85);
			bottom: 0;
			left: 0;
			padding: .694rem 1rem;
			position: absolute;
			width: 100%;
		}
		
		.Billboard-content--topLeft {
			bottom: auto;
			left: 1rem;
			right: auto;
			top: 1rem;
			
			/* Billboard-content with full width should be flush */
			&[class*='size1of1'] {
				top: 0;
			}
		}
		
		.Billboard-content--topRight {
			bottom: auto;
			left: auto;
			right: 1rem;
			top: 1rem;
			
			/* Billboard-content with full width should be flush */
			&[class*='size1of1'] {
				top: 0;
			}
		}
		
		.Billboard-content--bottomRight {
			bottom: 1rem;
			left: auto;
			right: 1rem;
			top: auto;
			
			/* Billboard-content with full width should be flush */
			&[class*='size1of1'] {
				bottom: 0;
			}
		}
		
		.Billboard-content--bottomLeft {
			bottom: 1rem;
			left: 1rem;
			right: auto;
			top: auto;
			
			&[class*='size1of1'] {
				bottom: 0;
			}
		}
	}
}

@media @mq-lg {
	.lg-Billboard--overlay {
	  .Billboard-content {
			background: rgba(255,255,255,.85);
			bottom: 0;
			left: 0;
			padding: .694rem 1rem;
			position: absolute;
			width: 100%;
		}
	}
}

.Billboard-title,
.Billboard-subhead {
	margin: 0;
}

.Billboard-title {
	font-size: 1.2rem;
	
	@media @mq-sm {
		font-size: 1.44rem;
	}
	
	@media @mq-md {
		font-size: 1.728rem;
	}
}

.Billboard-subhead {
	@media @mq-sm {
		font-size: 1.2em;
	}
}

.Billboard-action {
	font-family: @font-alt;
	font-style: italic;
	text-transform: uppercase;
}

.Billboard--md {
	font-size: 1.2em;
}

.Billboard--withBorder {
  border-bottom: 12px solid #000;
}

.Billboard-content--dark {
	background: rgba(0,0,0,.80);;
	color: #fff;
	
	a {
		color: inherit;
	}	
}