.u-paddingAn { padding: 0 !important; }
.u-paddingAs { padding: @sm-space !important; }
.u-paddingAm { padding: @md-space !important; }
.u-paddingAl { padding: @lg-space !important; }

.u-marginAn { margin: 0 !important; }
.u-marginAs { margin: @sm-space !important; }
.u-marginAm { margin: @md-space !important; }
.u-marginAl { margin: @lg-space !important; }
.u-paddingBn,
.u-paddingVn { padding-bottom: 0 !important; }
.u-paddingBs,
.u-paddingVs { padding-bottom: @sm-space !important; }
.u-paddingBm,
.u-paddingVm { padding-bottom: @md-space !important; }
.u-paddingBl,
.u-paddingVl { padding-bottom: @lg-space !important; }

.u-marginBn,
.u-marginVn { margin-bottom: 0 !important; }
.u-marginBs,
.u-marginVs { margin-bottom: @sm-space !important; }
.u-marginBm,
.u-marginVm { margin-bottom: @md-space !important; }
.u-marginBl,
.u-marginVl { margin-bottom: @lg-space !important; }

.u-paddingTn,
.u-paddingVn { padding-top: 0 !important; }
.u-paddingTs,
.u-paddingVs { padding-top: @sm-space !important; }
.u-paddingTm,
.u-paddingVm { padding-top: @md-space !important; }
.u-paddingTl,
.u-paddingVl { padding-top: @lg-space !important; }

.u-marginTn,
.u-marginVn { margin-top: 0 !important; }
.u-marginTs,
.u-marginVs { margin-top: @sm-space !important; }
.u-marginTm,
.u-marginVm { margin-top: @md-space !important; }
.u-marginTl,
.u-marginVl { margin-top: @lg-space !important; }

.u-paddingLs,
.u-paddingHs { padding-left: @sm-space !important; }

.u-paddingLm,
.u-paddingHm { padding-left: @md-space !important; }

.u-paddingLl,
.u-paddingHl { padding-left: @lg-space !important; }


@media @mq-sm {
	.u-sm-marginBn { margin-bottom: 0 !important; }
}

/* Padding
   ========================================================================== */

/* all */
.u-p-0 { padding: 0; }
.u-p-1 { padding: .25rem; }
.u-p-2 { padding: .5rem; }
.u-p-3 { padding: .75rem; }
.u-p-4 { padding: 1rem; }
.u-p-8 { padding: 2rem; }
.u-p-12 { padding: 3rem; }

/* top */
.u-pt-2 { padding-top: .5rem; }
.u-pt-5 { padding-top: 1.25rem; }
.u-pt-12 { padding-top: 3rem; }

/* horizontal */
.u-px-1 { padding-left: .25rem; padding-right: .25rem; }
.u-px-2 { padding-left: .5rem; padding-right: .5rem; }
.u-px-4 { padding-left: 1rem; padding-right: 1rem; }
.u-px-8 { padding-left: 2rem; padding-right: 2rem; }

/* vertical */
.u-py-2 { padding-bottom: .5rem; padding-top: .5rem; }
.u-py-4 { padding-bottom: 1rem; padding-top: 1rem; }
.u-py-8 { padding-bottom: 2rem; padding-top: 2rem; }
.u-py-16 { padding-bottom: 4rem; padding-top: 4rem; }

/* top */
.u-pt-2 { padding-top: .5rem; }
.u-pt-3 { padding-top: .75rem; }
.u-pt-4 { padding-top: 1rem; }
.u-pt-5 { padding-top: 1.25rem; }
.u-pt-12 { padding-top: 3rem; }

/* top */
.u-pt-5 { padding-top: 1.25rem; }
.u-pt-12 { padding-top: 3rem; }

/* right */
.u-pr-0 { padding-right: 0; }
.u-pr-2 { padding-right: .5rem; }

/* bottom */
.u-pb-0 { padding-bottom: 0; }
.u-pb-8 { padding-bottom: 2rem; }

/* left */
.u-pl-4 { padding-left: 1rem; }

@media @mq-sm {
	.sm\:u-p-8 { padding: 2rem; }
	.sm\:u-p-12 { padding: 3rem; }
}

/* Margin
   ========================================================================== */
/* all */
.u-m-0 { margin: 0; }
/* vertical */
.u-my-0 { margin-bottom: 0; margin-top: 0; }
.u-my-4 { margin-bottom: 1rem; margin-top: 1rem; }
.u-my-6 { margin-bottom: 1.5rem; margin-top: 1.5rem; }
/* horizontal */
.u-mx-auto { margin-left: auto; margin-right: auto; }
/* top */
.u-mt-0 { margin-top: 0; }
.u-mt-1 { margin-top: .25rem; }
.u-mt-4 { margin-top: 1rem; }
.u-mt-8 { margin-top: 2rem; }
.u-mt-12 { margin-top: 3rem; }
.u-mt-20 { margin-top: 5rem; }
/* right */
.u-mr-1 { margin-right: .25rem; }
.u-mr-2 { margin-right: .5rem; }
.u-mr-4 { margin-right: 1rem; }
/* bottom */
.u-mb-0 { margin-bottom: 0; }
.u-mb-1 { margin-bottom: .25rem; }
.u-mb-2 { margin-bottom: .5rem; }
.u-mb-3 { margin-bottom: .75rem; }
.u-mb-4 { margin-bottom: 1rem; }
.u-mb-6 { margin-bottom: 1.5rem; }
.u-mb-8 { margin-bottom: 2rem; }
.u-mb-12 { margin-bottom: 3rem; }
.u-mb-16 { margin-bottom: 4rem; }
.u-mb-20 { margin-bottom: 5rem; }
.u-mb-px { margin-bottom: 1px; }

/* margin left */
.u-ml-1 { margin-left: .25rem; }
.u-ml-2 { margin-left: .5rem; }
.u-ml-4 { margin-left: 1rem; }
.u-ml-auto { margin-left: auto; }


/* Negative margin
   ========================================================================== */

/* horizontal */
.u--mx-1 { margin-left: -.25rem; margin-right: -.25rem; }
.u--mx-2 { margin-left: -.5rem; margin-right: -.5rem; }
.u--mx-4 { margin-left: -1rem; margin-right: -1rem; }

/* top */
.u--mt-4 { margin-top: -1rem; }

@media @mq-sm {
  .sm\:u-mr-4 { margin-right: 1rem; }
}

@media @mq-lg {
	.lg\:u-p-6 { padding: 1.5rem; }
	.lg\:u-p-8 { padding: 2rem; }

	.lg\:u-mx-4 { margin-right: 1rem; margin-left: 1rem; }
	.lg\:u-mb-6 { margin-bottom: 1.5rem; }
}

@media @mq-xl {
	.xl\:u-px-4 { padding-left: 1rem; padding-right: 1rem; }

	.xl\:u-mx-12 { margin-right: 3rem; margin-left: 3rem; }
  .xl\:u-mb-4 { margin-bottom: 1rem; }

	.xl\:u--mx-4 { margin-left: -1rem; margin-right: -1rem; }
}
