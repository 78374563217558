.Alert {
	background: @grey-lightest;
	margin-bottom: 1rem;
	padding: 1rem;

	> :first-of-type {
		margin-top: 0;
	}

	> :last-of-type {
		margin-bottom: 0;
	}
}

.Alert--danger {
	background: lighten(red, 40%);
}
