.NavBar {
  background: @navbar-background-color;
  color: @grey-light;
	font-weight: 400;
	font-size: .875rem;
  position: sticky;
  top: 0;
  transition: transform ease 500ms;
  will-change: transform;
  z-index: 50;

  a {
    color: #fff;
    text-decoration: none;
  }

  > .Container {
		background: @navbar-background-color;
    padding: 0;
  }
}

.NavBar-scroll {
  height: @navbar-height;
}

.NavBar-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  scrollbar-width: none;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.NavBar-inner {
  position: relative;
}



.Nav {
  margin: 0;
  padding: 0;
}

.Nav-item {
  display: table-cell;
  line-height: 1;
  padding: 0 .833em;
  text-transform: uppercase;
  vertical-align: middle;

  &:last-child {
    padding-right: 100px; /* clears the overlaying gradient */
  }

	&:before {
		content: '';
		height: 1em;
		border-left: 1px solid white;
		display: inline-block;
		left: -.833em;
		position: relative;
		vertical-align: middle;


	}

	&:first-child:before {
		border-left: none;
	}
}

.Nav-action {
	display: inline-block;
	padding-bottom: 1em;
	padding-top: 1em;
}



.NavToggle {
	background: #000;
  display: flex;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;

  &::before {
    background: linear-gradient(to right, fade(@navbar-background-color, 0%) 0%,@navbar-background-color 100%);
    content: '';
    height: 100%;
    left: -20px;
    pointer-events: none;
    position: absolute;
    width: 20px;

    @media (min-width: 54em) { /*same min-width as toggle items below */
      display: none;
    }
  }
}

.NavToggle-item {
  border-left: 1px solid @grey-light;
  color: #fff;
  line-height: 3rem;
  padding-left: .75rem;
  padding-right: .75rem;
  position: relative;
  text-decoration: none;
  text-transform: lowercase;

  @media (min-width: 54em) {
    &:first-child {
      border-left: none;
    }
  }
}

/**
 * 1. Hide on initial load so the user can toggle it's display state using
 *    toggle buttons
 * 2. Shorten vertical padding compared to other NavToggle-item's because of
 *    the containing form's extra height.
 * 3. Must be table-cell to align itself with other NavToggle-item's
 */
.NavToggle-item--searchForm {
  border-left-color: @grey-light;
  display: none; /* 1 */

  &.is-expanded {
    display: table-cell; /* 3 */
  }

	.Search-input {
		background: @navbar-background-color;
		border: none;
		color: #fff;
		font-family: @font-alt;

		&:focus {
		  background: @navbar-background-color;
			border-color: transparent;
		}

		&::selection {
			background: #fff;
			color: #000;
		}
	}

  @media (min-width: 54em) {
    display: table-cell;
  }
}

.NavToggle-button {
	margin: 0;
}

.NavToggle-button--searchForm {
  .NavToggle-item--searchForm.is-expanded + & {
    img {
      display: none;
    }
    &:before {
      content: '\00d7';
      font-size: 1.44em;
      font-weight: 700;
      line-height: .694;
    }
  }

  @media (min-width: 54em) {
    display: none;
  }
}

.NavToggle-button--menu {
  display: inline-block;
  width: 1.728em;
  height: 1em;
  line-height: 0;
  border-top: .6em double #fff;
  border-bottom: .25em solid #fff;
  text-indent: 1.728em;
  overflow: hidden;
	vertical-align: middle;

  &.is-expanded {
    border: none;

    &:before {
      content: '\00d7';
      display: block;
      font-size: 2.074em;
      font-weight: 700;
      height: .833em;
      line-height: .4;
      margin-left: 0;
      text-align: center;
      text-indent: 0;
      width: .833em;
    }
  }
}

@media @mq-md {
	.NavBar {
		font-size: 1em;
	}

	.NavToggle-button--menu {
		border-bottom-width: .2em;
	}
}

.c-subnav {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
