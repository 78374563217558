.c-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(.25rem);
  background: rgba(255,255,255,.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.c-modal__container {
  max-height: 100vh;
  max-width: 45rem;
  overflow-y: auto;
  position: relative;
  width: 92%;
}

.c-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.c-modal__close {
  border: 0;
  text-align: center;
  transition: all 700ms ease;

  &:focus {
    outline: none;
  }

  &:hover {
    background: #fff;
  }
}

.c-modal__close:before { content: "\2715"; }

.c-modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0,0,0,.8);
}

.c-modal__btn {
  font-size: .875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #e6e6e6;
  color: rgba(0,0,0,.8);
  border-radius: .25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform .25s ease-out;
}

.c-modal__btn:focus, .c-modal__btn:hover {
  transform: scale(1.05);
}

.c-modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}

@keyframes modalFadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes modalFadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

@keyframes modalSlideIn {
  from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes modalSlideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

.c-modal--slide {
  display: none;
}

.c-modal--slide.is-open {
  display: block;
}

.c-modal--slide[aria-hidden="false"] .c-modal__overlay {
  animation: modalFadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.c-modal--slide[aria-hidden="false"] .c-modal__container {
  animation: modalSlideIn .3s cubic-bezier(0, 0, .2, 1);
}

.c-modal--slide[aria-hidden="true"] .c-modal__overlay {
  animation: modalFadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.c-modal--slide[aria-hidden="true"] .c-modal__container {
  animation: modalSlideOut .3s cubic-bezier(0, 0, .2, 1);
}

.c-modal--slide .c-modal__container,
.c-modal--slide .c-modal__overlay {
  will-change: transform;
}
