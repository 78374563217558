.SiteHeader,
.SiteMain,
.SiteFooter {
  > .Container {
		padding-bottom: 0;
		.u-cf;
  }
}

.SiteHeader {
  h1 {
    margin: 0;
    text-align: center;
  }

	h2 {
		background-image: url("../img/lb-Tagline.png");
		background-repeat: no-repeat;
		background-size: contain;
		margin: 1rem auto 0;
		max-width: 340px;
		width: 100%;

		span {
			padding-bottom: 6.55%;/* holds aspect ratio of background */
			text-indent: 505px;
			display: block;
			overflow: hidden;
			height: 0;
			white-space: nowrap;
		}
	}

  .GlobalNav {
    max-height: 0;
    overflow: hidden;
    transition: max-height ease .4s;
    z-index: 9998;
  }

  &.is-expanded {
    .GlobalNav {
      bottom: 0;
      max-height: 2000px;
      overflow-y: auto;
      position: fixed;
      top: @navbar-height;
      width: 100%;

			> .Container {
				min-height: ~"calc(100vh - 2.986em)";
			}
    }
  }
}

.SiteMain {
  > .Container {
    padding-bottom: 0;
  }
}

.SiteFooter {
	background-color: #000;
	color: @grey-lighter;
	&,
	.Grid-cell {
		font-size: .833rem;
	}

	.Box {
		color: @grey-lighter;
		/* color: darken(@grey-light, 15%); */
	}

	.Box-body {
		text-transform: uppercase;
	}

	a {
		color: inherit;
		font-weight: 400;
	}

	.ListBare {

	}

	> .Container {
		border-top: 1px solid #000;
		padding: 2.074em 1.2em 6.192em; /* add extra horizontal padding to accomodate smaller font size */
	}
}

@media @mq-md {
	.SiteHeader {
		h2 {
			float: right;
			margin-top: 0;
		}
	}
}

@media @mq-lg {
	.SiteFooter {
		> .Container {
			padding-bottom: 2.986em;
		}
	}

	.SiteMain {
		> .Container {
			padding: 1.5rem 1.5rem 0;
		}

		.Layout {
			margin: 0 -1.5rem;
		}
	}
}
