.Pager {
	list-style: none;
	margin: 1em 0;
	padding: 0;
	.u-cf;
	
	a {
		color: @grey-light;
	}
}

.Pager-item {
	font-family: @font-alt;
	line-height: 1;
	position: relative;
	text-transform: uppercase;
	width: 50%;
}

.Pager-item--left {
	float: left;
	&:before {
		border: .402em solid transparent;
		border-right-color: @grey-light;
		border-left-width: 0;
		content: '';
		display: inline-block;
		height: 0;
		margin-right: 4px;
		width: 0;
	}
}

.Pager-item--right {
	float: right;
	text-align: right;
	
	&:after {
		border: .402em solid transparent;
		border-left-color: @grey-light;
		border-right-width: 0;
		content: '';
		display: inline-block;
		height: 0;
		margin-left: 4px;
		width: 0;
	}
}