.MediaObject {
	.u-cf;
	width: 100%;
}

.MediaObject-figure {
	margin-bottom: 1em;
}

.MediaObject-body {
	overflow: hidden;
	
	> h2,
	> h3,
	> h4,
	> h5,
	> h6 {
		font-weight: 600;
		margin: 0;
		
		+ ul,
		+ ol,
		+ p {
			margin-top: .482em;
		}
	}
}

.MediaObject-meta {
	color: @grey-dark;
	font-weight: 600;
	text-transform: uppercase;
}

.MediaObject-title {
	font-size: 1.44em;
	font-weight: 300;
	line-height: 1.2;
	margin: 0;
	
	@media @mq-sm {
		font-size: 1.728em;
	}
	
	@media @mq-lg {
		font-size: 2.074em;
	}
}

.MediaObject-title,
.MediaObject-subhead {
	color: #000;
}

.MediaObject-footer {
	font-family: @font-alt;
	font-size: .833em;
	font-style: italic;
	list-style: none;
	margin: 1em 0 0;
	padding: 0;
	
	
	a {
		color: inherit;
	}
	
	@media @mq-sm {
	  line-height: 1.2;
	}
}

.MediaObject-footerItem {
	@media @mq-sm {
		border-left: 1px solid currentcolor;
		display: inline-block;
		margin-right: -.279em;
		padding: 0 .482em;
		
		&:first-child {
			border: none;
			padding-left: 0;
		}
	}
}

.MediaObject-byline {
	text-transform: uppercase;
}

.MediaObject--list {
	border-bottom: 1px dotted currentcolor;
	padding: .579em 0;
}

.MediaObject--row {
	display: table;
	
	.MediaObject-figure,
	.MediaObject-body {
		display: table-cell;
		min-width: 100px;
	}
	
	.MediaObject-body {
		padding-left: 1em;
	}
}

.MediaObject--alignTop {
	.MediaObject-figure,
	.MediaObject-body {
		vertical-align: top;
	}
}

.MediaObject--alignMiddle {
	.MediaObject-figure,
	.MediaObject-body {
		vertical-align: middle;
	}
}

@media @mq-sm {
	.sm-MediaObject--row {
		display: table;
		
		.MediaObject-figure,
		.MediaObject-body {
			display: table-cell;
		}
		
		.MediaObject-body {
			padding-left: 1em;
		}
	}
}

@media @mq-md {
	.md-MediaObject--row {
		display: table;
		
		.MediaObject-figure,
		.MediaObject-body {
			display: table-cell;
		}
		
		.MediaObject-body {
			padding-left: 1em;
		}
	}
}

@media @mq-lg {
	.lg-MediaObject--row {
		display: table;
		
		.MediaObject-figure,
		.MediaObject-body {
			display: table-cell;
		}
		
		.MediaObject-body {
			padding-left: 1em;
		}
	}
}