.ShareBar {
	border-bottom: 1px solid @grey-light;
	list-style: none;
	margin: 1em 0;
	padding: 1em 0;
	
	a {
		color: @grey;
		text-decoration: none !important;
	}
}

.ShareBar-item {
	display: inline-block;
	margin-right: -.279em;
	padding: 0 .482em;
	text-align: center;
	width: 33.3333%;
	
	&:last-child {
		margin-right: 0;
	}
	
	.Icon {
		display: block;
	}
}

.ShareBar--right {
	text-align: right;
		
	&:first-child {
		padding-left: 0;
	}
}

@media @mq-sm {
	.ShareBar-item {
		width: auto;
		
		.Icon {
			display: inline-block;
		}
	}
}