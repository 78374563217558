.Ad {
	margin: 1em auto;
	padding: 10px 0;
	width: 100%;
}

.Ad--bordered {
	border-bottom: 1px solid @grey;
	border-top: 1px solid @grey;
}

.Ad--billboard {
	max-width: 970px;
}

.Ad--mr {
	max-width: 300px;
}