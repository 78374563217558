/*
 * Lowercase text
 */
.u-textLowercase {
	text-transform: lowercase;
}

/*
 * Uppercase text
 */
.u-textUppercase {
	text-transform: uppercase;
}

/*
 * Grey text
 */
.u-textGrey {
	color: @grey-light;
}

/**
 * Word breaking
 *
 * Break strings when their length exceeds the width of their container.
 */

.u-textBreak {
  word-wrap: break-word !important;
}

/**
 * Horizontal text alignment
 */

.u-textCenter {
  text-align: center !important;
}

.u-textLeft {
  text-align: left !important;
}

.u-textRight,
.u-text-right {
  text-align: right;
}

@media @mq-md {
	.u-md-textRight {
		text-align: right !important;
	}
}

/**
 * Inherit the ancestor's text color.
 */

.u-textInheritColor {
  color: inherit !important;
}

/**
 * Enables font kerning in all browsers.
 * http://blog.typekit.com/2014/02/05/kerning-on-the-web/
 *
 * 1. Chrome (not Windows), Firefox, Safari 6+, iOS, Android
 * 2. Chrome (not Windows), Firefox, IE 10+
 * 3. Safari 7 and future browsers
 */

.u-textKern {
  text-rendering: optimizeLegibility; /* 1 */
  font-feature-settings: "kern" 1; /* 2 */
  font-kerning: normal; /* 3 */
}

/**
 * Prevent whitespace wrapping
 */

.u-textNoWrap {
  white-space: nowrap !important;
}

/**
 * Text truncation
 *
 * Prevent text from wrapping onto multiple lines, and truncate with an
 * ellipsis.
 *
 * 1. Ensure that the node has a maximum width after which truncation can
 *    occur.
 * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
 *    nodes.
 */

.u-textTruncate {
  max-width: 100%; /* 1 */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important; /* 2 */
}

.u-textLg {
	font-size: 2.488em;
	line-height: 1.2;
}

.u-textMd {
	font-size: 1.44em;
}

.u-textSm {
	font-size: .694em;
}

.u-textItalic {
	font-style: italic;
}

.u-gamma {
	font-size: 1.44rem;
}
/* Color
   ========================================================================== */
.u-text-current { color: currentColor; }

.u-text-grey-600,
.u-text-grey { color: @grey; }

.u-text-gray-200 { color: @gray-200; }
.u-text-gray-300 { color: @gray-300; }
.u-text-gray-400 { color: @gray-400; }
.u-text-gray-500 { color: @gray-500; }
.u-text-gray-600 { color: @gray-600; }
.u-text-gray-700 { color: @gray-700; }
.u-text-gray-800 { color: @gray-800; }
.u-text-gray-900 { color: @gray-900; }

.u-text-red,
.u-text-red-500 { color: @red; }

.u-text-pink-200 { color: @pink-200; }

.u-text-white { color: #fff; }
.u-text-black { color: #000; }

.u-text-teal-500 { color: @teal-500; }
.u-text-teal-800 { color: @teal-800; }


/* Font Family
   ========================================================================== */
.u-font-display { font-family: @font-alt; }


/* Font Size
   ========================================================================== */
.u-text-xs { font-size: .75rem; }
.u-text-sm { font-size: .875rem; }
.u-text-base { font-size: 1rem; }
.u-text-lg { font-size: 1.125rem; }
.u-text-xl { font-size: 1.25rem; }
.u-text-2xl { font-size: 1.5rem; }
.u-text-3xl { font-size: 1.875rem; }
.u-text-4xl { font-size: 2.25rem; }
.u-text-5xl { font-size: 3rem; }

@media @mq-sm {
	.sm\:u-text-5xl { font-size: 3rem; }
}

@media @mq-md {
	.md\:u-text-base { font-size: 1rem; }
	.md\:u-text-xl { font-size: 1.25rem; }
	.md\:u-text-4xl { font-size: 2.25rem; }
	.md\:u-text-5xl { font-size: 3rem; }
}


/* Font Weight
   ========================================================================== */
.u-font-normal { font-weight: 400; }
.u-font-medium { font-weight: 500; }
.u-font-semibold { font-weight: 600; }
.u-font-bold { font-weight: 700; }

/* Letter Spacing
	 ========================================================================== */
.u-tracking-wide { letter-spacing: 0.025rem; }

/* Letter Spacing
	 ========================================================================== */
.u-leading-none { line-height: 1; }
.u-leading-tight { line-height: 1.25; }
.u-leading-3 { line-height: .75rem; }

/* Style & decoration
   ========================================================================== */
.u-italic { font-style: italic; }
.u-uppercase { text-transform: uppercase; }
.u-underline { text-decoration: underline; }
.u-line-through { text-decoration: line-through; }
.u-no-underline { text-decoration: none; }


/* Text Alignment
   ========================================================================== */
.u-text-center { text-align: center; }

/* Whitespace
   ========================================================================== */
.u-whitespace-no-wrap { white-space: nowrap; }
