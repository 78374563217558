a {
	color: @link-color-base;
	text-decoration: none;
	
	&:hover,
	&:focus,
	&:active {
		text-decoration: underline;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.2;
	
	a {
		color: inherit;
	}
}

h1 { font-size: 2.488em; }
h2 { font-size: 1.728em; }
h3 { font-size: 1.44em; }
h4 { font-size: 1.2em; }
h5,
h6 { font-size: 1em; }

nav ul,
nav ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}