.Icon {
  display: inline-block;
  font-size: 1.2em;
  line-height: 1;
  vertical-align: middle;
	
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
}

.Icon--lg {
	font-size: 2.074em;
}

.IconExpand {
  width: .579em;
  height: .482em;
  border: .063em solid currentColor;
  border-radius: 2px;
  position: relative;
	
	&::before,
	&::after {
		border: .063em solid currentColor;
		content: '';
		display: block;
		position: absolute;
	}
	
	&::before {
		border-bottom: none;
		border-radius: 2px 2px 0 0;
		left: 50%;
		height: 50%;
		top: -50%;
		width: ~"calc(100% + .063em)";
		
	}
	
	&:after {
		border-left: 0;
		border-radius: 0 0 2px 0;
		border-top: none;
		height: ~"calc(50% + .063em)";
		left: 100%;
		top: 0;
		width: ~"calc(50% + .063em)";
	}
}

.IconArrow {
	border: solid transparent;
	border-color: transparent;
	border-width: .335em;
	position: relative;
}

.IconArrow--right {
	border-left-color: currentColor;
	border-right: none;
}