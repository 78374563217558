/* Grid Template Columns
   ========================================================================== */
.u-grid-cols-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }

/* Grid Column Start / End
   ========================================================================== */
.u-col-span-2 { grid-column: span 2 / span 2; }

/* Grid Template Rows
   ========================================================================== */
.u-grid-rows-2 { grid-template-rows: repeat(2, minmax(0, 1fr)); }

/* Grid Auto Flow
   ========================================================================== */
.u-grid-flow-col { grid-auto-flow: column; }

/* Grid Gap
   ========================================================================== */
.u-gap-4 { gap: 1rem; }
.u-gap-8 { gap: 2rem; }

@media @mq-sm {
  .sm\:u-grid-cols-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .sm\:u-grid-cols-6 { grid-template-columns: repeat(6, minmax(0, 1fr)); }

  .sm\:u-col-span-2 { grid-column: span 2 / span 2; }
  .sm\:u-col-span-4 { grid-column: span 4 / span 4; }
}

@media @mq-md {
  .md\:u-grid-cols-12 { grid-template-columns: repeat(12, minmax(0, 1fr)); }
  .md\:u-grid-rows-2 { grid-template-rows: repeat(2, minmax(0, 1fr)); }

  .md\:u-col-span-4 { grid-column: span 4 / span 4; }
  .md\:u-col-span-8 { grid-column: span 8 / span 8; }

  .md\:u-row-span-2	{ grid-row: span 2 / span 2; }

  .md\:u-grid-flow-col { grid-auto-flow: column; }

  .md\:u-gap-8 { gap: 2rem; }
}
